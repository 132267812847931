import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyIdentityUser } from "../../features/auth/authSlice";
import {
  Box,
  Typography,
  CircularProgress,
  Container,
  Paper,
  Snackbar,
  Alert
} from "@mui/material";

const VerifyIdentity = () => {
  const [searchParams] = useSearchParams();
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const is_completed = Boolean(searchParams.get("is_completed"));
  const userId = searchParams.get("userId");
  const sessionId = searchParams.get("sessionId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (is_completed && userId && sessionId) {
      try {
        dispatch(verifyIdentityUser({ userId, sessionId })).then(
          ({ payload }) => {
            if (payload.token) {
              setTimeout(
                () => navigate("/", { state: { openConnectSetup: true } }),
                1000
              );
            }
          }
        );
        setSnackbarMessage("Registered Successfully!");
        setOpenSnackbar(true);
      } catch (err) {
        setSnackbarMessage("Try Again! Complete your payment first!");
        setOpenSnackbar(true);
        console.error(err);
      }
    } else {
      setSnackbarMessage("Try Again! Complete your payment first!");
      setOpenSnackbar(true);
      navigate("/");
    }
  }, [userId, sessionId]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={1000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage?.includes("Success") ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <CircularProgress color="primary" sx={{ mb: 3 }} />
          <Typography variant="h5" component="h2" gutterBottom>
            Verifying your identity...
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Please wait while we verify your identity. You will be redirected
            shortly.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default VerifyIdentity;
