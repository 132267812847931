function formatNumber(num) {
  if (!num) return 0;
  const number = +num;
  // Check if the number is an integer
  if (number % 1 === 0) {
    return number.toFixed(0); // Remove decimals (rounds to the nearest integer)
  } else {
    return Math.round(+number.toString()); // Leave decimal part as is
  }
}

export default formatNumber;
