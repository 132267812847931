import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import { generateSignedUrl } from "../../utils/getPreSignedURL";
import "./RichTextEditor.css";
import BlotFormatter from "quill-blot-formatter";
import { Box } from "@mui/material";
Quill.register("modules/blotFormatter", BlotFormatter);

const ImageBlot = Quill.import("formats/image");

class CustomStyledImageBlot extends ImageBlot {
  static create(value) {
    const node = super.create(value);
    node.setAttribute("src", value.src);
    node.style.display = value.display || "inline";
    node.style.float = value.float || "none";
    node.style.margin = value.margin || "0px";
    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute("src"),
      display: node.style.display,
      float: node.style.float,
      margin: node.style.margin,
      width: node.style.width,
      height: node.style.height
    };
  }
}

CustomStyledImageBlot.blotName = "customStyledImage";
CustomStyledImageBlot.tagName = "img";
Quill.register(CustomStyledImageBlot);

const RichTextEditor = forwardRef(
  ({ defaultValue, value, setValue, isLargeEditorNeeded = false }, ref) => {
    const quillRef = useRef(null);

    // Expose the resetSelection method to parent components
    useImperativeHandle(ref, () => ({
      resetSelection: () => {
        if (quillRef.current) {
          const editor = quillRef.current.getEditor();
          editor.setSelection(null);
          // Also remove any active formatting overlays
          const blotFormatter = editor.getModule("blotFormatter");
          if (blotFormatter) {
            blotFormatter.hide();
          }
        }
      }
    }));

    const updateImageSources = useCallback(
      async (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const images = Array.from(doc.querySelectorAll("img"));

        const imageUpdates = images.map(async (img) => {
          const fileName = img.getAttribute("src");
          if (img.getAttribute("width"))
            img.style.width = img.getAttribute("width");
          if (img.getAttribute("height"))
            img.style.height = img.getAttribute("height");

          if (fileName && !fileName.startsWith("http")) {
            try {
              const signedUrl = await generateSignedUrl(
                fileName,
                process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
              );
              img.src = signedUrl;
            } catch (error) {
              console.error("Error generating signed URL:", error);
            }
          }
        });

        await Promise.all(imageUpdates);
        setValue(doc.body.innerHTML);
      },
      [setValue]
    );

    useEffect(() => {
      if (defaultValue) updateImageSources(defaultValue);
    }, [defaultValue, updateImageSources]);

    return (
      <Box
        className="quill-wrapper"
        sx={
          isLargeEditorNeeded && {
            "& .ql-container": {
              height: "600px !important",
              maxHeight: "unset !important"
            }
          }
        }
      >
        <ReactQuill
          style={{ padding: "30px 0" }}
          className="ql-editor"
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={(text, delta, source) => {
            if (source === "user") {
              setValue(text);
            }
          }}
          formats={[
            "customStyledImage",
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
            "float",
            "align",
            "color",
            "background",
            "font",
            "style",
            "height",
            "width",
            "alt",
            "size"
          ]}
          modules={{
            blotFormatter: {},
            toolbar: {
              container: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["link", "image"],
                ["clean"],
                [{ size: ["small", false, "large", "huge"] }]
              ],
              clipboard: { matchVisual: false }
            },
            clipboard: {
              matchVisual: false
            }
          }}
        />
      </Box>
    );
  }
);

export default RichTextEditor;
