import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
  Divider,
  Alert,
  Stack
} from "@mui/material";
import {
  Close,
  School,
  MenuBook,
  ArrowForward,
  ErrorOutline
} from "@mui/icons-material";
import { suggestionData } from "../../utils/mockLevelData";

const SuggestionModal = ({ open, handleClose, handleNavigate }) => {
  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="suggestion-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "50%" },
          maxHeight: "90vh",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: "auto"
        }}
      >
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Alert
            severity="info"
            icon={<ErrorOutline />}
            sx={{ bgcolor: "#e3f2fd" }}
          >
            Please add the missing level data as it's required for proper system
            functionality.
          </Alert>
        </Stack>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Suggestion Template
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Box display="flex" gap={2}>
          {/* Levels Section */}
          <Paper
            elevation={2}
            sx={{ width: "50%", p: 3, mb: 3, bgcolor: "#f8f9fa" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <School sx={{ color: "#1976d2" }} />
              <Typography variant="h6" sx={{ color: "#1976d2" }}>
                Levels
              </Typography>
            </Box>

            {Object.entries(suggestionData.levels).map(
              ([level, data], index) => (
                <Box key={level} sx={{ mb: index !== 2 ? 2 : 0 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#1976d2" }}
                  >
                    {level.replace("level", "Level ")}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666", mb: 0.5 }}>
                    Title: {data.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Description: {data.description}
                  </Typography>
                </Box>
              )
            )}
          </Paper>

          {/* Diary Levels Section */}
          <Paper
            elevation={2}
            sx={{ width: "50%", p: 3, mb: 3, bgcolor: "#f8f9fa" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <MenuBook sx={{ color: "#9c27b0" }} />
              <Typography variant="h6" sx={{ color: "#9c27b0" }}>
                Diary Levels
              </Typography>
            </Box>

            {Object.entries(suggestionData.diaryLevels).map(
              ([level, data], index) => (
                <Box key={level} sx={{ mb: index !== 2 ? 2 : 0 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#9c27b0" }}
                  >
                    {level.replace("level", "Level ")}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666", mb: 0.5 }}>
                    Title: {data.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    Description: {data.description}
                  </Typography>
                </Box>
              )
            )}
          </Paper>
        </Box>

        {/* Footer */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={handleNavigate}
            sx={{
              background: "linear-gradient(45deg, #1976d2 30%, #9c27b0 90%)",
              color: "white",
              "&:hover": {
                background: "linear-gradient(45deg, #1565c0 30%, #7b1fa2 90%)"
              }
            }}
          >
            Use Template
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuggestionModal;
