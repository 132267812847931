import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import useThemeColor from "../../utils/useTheme";
import { Alert, Snackbar } from "@mui/material";

function DateRangePicker({
  existingRanges,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  currentLevel,
  currentRecordId,
  setHasErrors
}) {
  const styles = useThemeColor();
  const normalizeDate = (date) => date && date.format("MM-DD");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const doRangesOverlap = (start1, end1, start2, end2) => {
    return start1 <= end2 && end1 >= start2;
  };

  // Check if a date is within any existing range
  const isDateDisabled = (date) => {
    const normalizedDate = normalizeDate(date);
    return existingRanges.some(({ startDate, endDate, level, id }) => {
      const normalizedStart = normalizeDate(dayjs(startDate));
      const normalizedEnd = normalizeDate(dayjs(endDate));
      if (id === currentRecordId) {
        return false;
      }
      return (
        level === currentLevel &&
        currentLevel?.split(" ")?.join("")?.toLowerCase() &&
        normalizedStart &&
        normalizedEnd &&
        normalizedDate >= normalizedStart &&
        normalizedDate <= normalizedEnd
      );
    });
  };

  const validateDateRange = (newStartDate, newEndDate) => {
    if (!newStartDate || !newEndDate) return true;

    return !existingRanges.some(({ startDate, endDate, level, id }) => {
      if (id === currentRecordId) return false;

      if (level !== currentLevel) return false;

      const existingStart = dayjs(startDate);
      const existingEnd = dayjs(endDate);

      return doRangesOverlap(
        newStartDate.startOf("day"),
        newEndDate.startOf("day"),
        existingStart.startOf("day"),
        existingEnd.startOf("day")
      );
    });
  };

  // Handle start date change
  const handleStartDateChange = (newStartDate) => {
    // First check if the new start date itself is valid
    if (isDateDisabled(newStartDate)) {
      setSnackbarMessage("This start date overlaps with an existing record.");
      setOpenSnackbar(true);
      setHasErrors(true);
      return;
    } else if (endDate && !validateDateRange(newStartDate, endDate)) {
      setSnackbarMessage("This date range overlaps with an existing record.");
      setOpenSnackbar(true);
      setHasErrors(true);
      return;
    } else {
      setStartDate(newStartDate);
      setHasErrors(false);
    }
  };

  // Handle end date change
  const handleEndDateChange = (newEndDate) => {
    if (startDate && newEndDate.isBefore(startDate)) {
      // Do not allow end date to be before the start date
      setSnackbarMessage("End date cannot be before the start date.");
      setOpenSnackbar(true);
      setEndDate(null);
      setHasErrors(true);
      return;
    }

    // Check if the new end date itself is valid
    if (isDateDisabled(newEndDate)) {
      setSnackbarMessage("This end date overlaps with an existing record.");
      setOpenSnackbar(true);
      setEndDate(null);
      setHasErrors(true);
      return;
    } else if (startDate && !validateDateRange(startDate, newEndDate)) {
      setSnackbarMessage("This date range overlaps with an existing record.");
      setOpenSnackbar(true);
      setEndDate(null);
      setHasErrors(true);
      return;
    } else {
      setEndDate(newEndDate);
      setHasErrors(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          ".MuiFormLabel-root": {
            color: styles.inputLabelColor
          },
          ".MuiTextField-root": {
            border: `1px solid ${styles.lightGreyColor}`,
            borderRadius: "4px"
          },
          ".MuiInputBase-root": {
            input: {
              color: styles.color
            }
          }
        }}
      >
        {/* Start Date Picker */}
        <DatePicker
          label="Start Date"
          value={startDate}
          disablePast
          shouldDisableDate={isDateDisabled}
          onChange={handleStartDateChange}
          //   renderInput={(params) => <Button {...params} />}
          sx={{
            ".Mui-error": {
              "& fieldset": {
                borderColor: "rgb(203,203,203) !important" // Important might be needed here
              }
            },
            color: styles.color // Label color for Start Date
          }}
          InputProps={{
            style: { color: styles.color, border: `1px solid ${styles.color}` } // Apply color to the label
          }}
          slots={{
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
        />
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {/* End Date Picker */}
        <DatePicker
          label="End Date"
          value={endDate}
          disablePast
          shouldDisableDate={isDateDisabled}
          onChange={handleEndDateChange}
          renderInput={(params) => <Button {...params} />}
          minDate={startDate} // Prevent selecting an end date before the start date
          sx={{
            ".Mui-error": {
              "& fieldset": {
                borderColor: "rgb(203,203,203) !important" // Important might be needed here
              }
            },
            color: styles.color // Label color for Start Date
          }}
          InputProps={{
            style: { color: styles.color } // Apply color to the label
          }}
          slots={{
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
