import React, { Suspense, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import RichTextEditor from "./NotesRichTextEditor";
import { selectCurrentSuperAdmin } from "../../features/auth/authSelectors";
import { getCurrentUser, updateAdminData } from "../../features/auth/authSlice";
import { deleteFile } from "../../utils/deleteFile";
import { dataURLtoFile } from "../../utils/dataURLtoFile";
import { uploadFile } from "../../utils/uploadFile";

const Notes = () => {
  const admin = useSelector(selectCurrentSuperAdmin);
  const [noteType, setNoteType] = useState("Gym");
  const defaultValue = useMemo(
    () => admin?.adminNotes?.[noteType],
    [admin, noteType]
  );
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const handleNoteTypeChange = (event) => {
    setNoteType(event.target.value);
    setValue(admin?.adminNotes?.[event.target.value] || "");

    // Reset the editor selection
    if (editorRef.current) {
      editorRef.current.resetSelection();
    }
  };

  const handleSubmit = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const images = Array.from(doc.querySelectorAll("img"));

    const prevUrls = new Set();
    if (defaultValue) {
      const prevDoc = parser.parseFromString(defaultValue, "text/html");
      const prevImages = Array.from(prevDoc.querySelectorAll("img"));
      for (let prevImg of prevImages) {
        const prevSrc = prevImg.getAttribute("src");
        if (prevSrc) {
          prevUrls.add(prevSrc);
        }

        if (!value?.includes(prevSrc) && !prevSrc.startsWith("http")) {
          try {
            await deleteFile(
              prevSrc,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );
          } catch (error) {
            console.error("Error deleting previous image:", error);
          }
        }
      }
    }
    for (let img of images) {
      let src = img.getAttribute("src");
      const originalSrc = src.split("?")[0];
      const fileName = originalSrc ? originalSrc?.split("/")?.at(-1) : "";

      if (src.includes(fileName)) {
        if (prevUrls.has(fileName)) {
          img.setAttribute("src", fileName);
          continue;
        }
      }

      if (src.startsWith("data:image")) {
        const file = dataURLtoFile(src, `image-${Date.now()}.png`);
        const uniqueFileName = `${Date.now()}-${file.name}`;
        try {
          await uploadFile(
            file,
            uniqueFileName,
            process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
          );

          img.setAttribute("src", uniqueFileName);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    }

    dispatch(
      updateAdminData({ userType: noteType, adminNotes: doc.body.innerHTML })
    );
    setTimeout(() => dispatch(getCurrentUser()), 1500);
  };

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <RadioGroup
          row
          value={noteType}
          onChange={handleNoteTypeChange}
          sx={{
            gap: 2,
            "& .MuiFormControlLabel-root": {
              margin: 0
            }
          }}
        >
          {[
            { name: "Gyms", value: "Gym" },
            { name: "Coaches", value: "Coach" },
            { name: "Companies", value: "Company" }
          ].map(({ name, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Radio
                  sx={{
                    display: "none"
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #38B65B",
                    backgroundColor:
                      noteType === value ? "#38B65B" : "transparent",
                    color: noteType === value ? "#fff" : "#38B65B",
                    cursor: "pointer",
                    fontWeight: 500,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor:
                        noteType === value
                          ? "#38B65B"
                          : "rgba(56, 182, 91, 0.1)"
                    }
                  }}
                >
                  {name}
                </Box>
              }
            />
          ))}
        </RadioGroup>

        <Button
          sx={{
            fontSize: "14px",
            padding: "16px 24px",
            background: "#38B65B",
            border: "1px solid #38B65B",
            color: "#fff",
            fontWeight: 700,
            textTransform: "none",
            height: "40px"
          }}
          onClick={handleSubmit}
          variant="outlined"
        >
          Update
        </Button>
      </Box>
      <Box width="100%">
        <Suspense fallback={<div>Loading...</div>}>
          <RichTextEditor
            ref={editorRef}
            isLargeEditorNeeded
            defaultValue={defaultValue}
            value={value}
            setValue={setValue}
          />
        </Suspense>
      </Box>
    </Box>
  );
};

export default Notes;
