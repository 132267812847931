import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  Tooltip
} from "@mui/material";
import {
  MenuBook,
  School,
  Stars,
  Edit,
  Description,
  Send,
  Grade,
  AutoStories,
  EmojiEvents
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSuperAdmin } from "../../features/auth/authSelectors";
import { suggestionData } from "../../utils/mockLevelData";
import { addNewLevels, fetchUser } from "../../api/authApi";
import { getCurrentUser } from "../../features/auth/authSlice";

const LevelsForm = ({ useTemplateData = false }) => {
  const { levelDetails = {} } = useSelector(selectCurrentSuperAdmin);
  const [formData, setFormData] = useState({
    levels: {
      level1: { title: "", description: "" },
      level2: { title: "", description: "" },
      level3: { title: "", description: "" }
    },
    diaryLevels: {
      level1: { title: "", description: "" },
      level2: { title: "", description: "" },
      level3: { title: "", description: "" }
    }
  });

  useEffect(() => {
    setFormData({
      ...formData,
      levels: {
        level1: levelDetails?.levels?.level1 || { title: "", description: "" },
        level2: levelDetails?.levels?.level2 || { title: "", description: "" },
        level3: levelDetails?.levels?.level3 || { title: "", description: "" }
      },
      diaryLevels: {
        level1: levelDetails?.diaryLevels?.level1 || {
          title: "",
          description: ""
        },
        level2: levelDetails?.diaryLevels?.level2 || {
          title: "",
          description: ""
        },
        level3: levelDetails?.diaryLevels?.level3 || {
          title: "",
          description: ""
        }
      }
    });
  }, [levelDetails]);

  useEffect(() => {
    if (useTemplateData)
      setFormData({
        ...formData,
        levels: {
          level1: suggestionData?.levels?.level1 || {
            title: "",
            description: ""
          },
          level2: suggestionData?.levels?.level2 || {
            title: "",
            description: ""
          },
          level3: suggestionData?.levels?.level3 || {
            title: "",
            description: ""
          }
        },
        diaryLevels: {
          level1: suggestionData?.diaryLevels?.level1 || {
            title: "",
            description: ""
          },
          level2: suggestionData?.diaryLevels?.level2 || {
            title: "",
            description: ""
          },
          level3: suggestionData?.diaryLevels?.level3 || {
            title: "",
            description: ""
          }
        }
      });
  }, [levelDetails]);

  const handleChange = (section, level, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [level]: {
          ...prev[section][level],
          [field]: value
        }
      }
    }));
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await addNewLevels(formData);
    await dispatch(getCurrentUser());
  };

  // Get icon based on level
  const getLevelIcon = (level, isLevels = true) => {
    if (isLevels) {
      return level === 1 ? (
        <School />
      ) : level === 2 ? (
        <Grade />
      ) : (
        <EmojiEvents />
      );
    }
    return level === 1 ? (
      <MenuBook />
    ) : level === 2 ? (
      <AutoStories />
    ) : (
      <Stars />
    );
  };

  const renderLevelInputs = (section, isLevels = true) => {
    return [1, 2, 3].map((level) => (
      <Paper
        key={`${section}-level${level}`}
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          background: isLevels
            ? `linear-gradient(135deg, #f5f7fa 0%, ${
                level === 1 ? "#e3f2fd" : level === 2 ? "#e8f5e9" : "#fff3e0"
              } 100%)`
            : `linear-gradient(135deg, #f5f7fa 0%, ${
                level === 1 ? "#f3e5f5" : level === 2 ? "#e8eaf6" : "#fce4ec"
              } 100%)`,
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)"
          }
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton
            size="small"
            sx={{
              bgcolor: isLevels
                ? level === 1
                  ? "#2196f3"
                  : level === 2
                  ? "#4caf50"
                  : "#ff9800"
                : level === 1
                ? "#9c27b0"
                : level === 2
                ? "#3f51b5"
                : "#e91e63",
              color: "white",
              mr: 1,
              "&:hover": {
                bgcolor: isLevels
                  ? level === 1
                    ? "#1976d2"
                    : level === 2
                    ? "#388e3c"
                    : "#f57c00"
                  : level === 1
                  ? "#7b1fa2"
                  : level === 2
                  ? "#303f9f"
                  : "#c2185b"
              }
            }}
          >
            {getLevelIcon(level, isLevels)}
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              color: isLevels
                ? level === 1
                  ? "#1976d2"
                  : level === 2
                  ? "#388e3c"
                  : "#f57c00"
                : level === 1
                ? "#7b1fa2"
                : level === 2
                ? "#303f9f"
                : "#c2185b"
            }}
          >
            Level {level}
          </Typography>
        </Box>

        <Box sx={{ ml: 5 }}>
          <TextField
            fullWidth
            placeholder="Enter title"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <Edit sx={{ mr: 1, fontSize: 18, color: "text.secondary" }} />
              ),
              sx: { fontSize: "13px" }
            }}
            sx={{ mb: 2 }}
            value={formData[section][`level${level}`].title}
            onChange={(e) =>
              handleChange(section, `level${level}`, "title", e.target.value)
            }
          />

          <TextField
            fullWidth
            placeholder="Enter description"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <Description
                  sx={{ mr: 1, fontSize: 18, color: "text.secondary" }}
                />
              ),
              sx: { fontSize: "13px" }
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            value={formData[section][`level${level}`].description}
            onChange={(e) =>
              handleChange(
                section,
                `level${level}`,
                "description",
                e.target.value
              )
            }
          />
        </Box>
      </Paper>
    ));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Both sections side by side */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{
                p: 3,
                bgcolor: "white",
                borderRadius: 2
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  color: "#1976d2",
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <School sx={{ fontSize: 28 }} /> Training Levels
              </Typography>
              {renderLevelInputs("levels")}
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{
                p: 3,
                bgcolor: "white",
                borderRadius: 2
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  color: "#9c27b0",
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <MenuBook sx={{ fontSize: 28 }} /> Diary Levels
              </Typography>
              {renderLevelInputs("diaryLevels", false)}
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            endIcon={<Send />}
            sx={{
              px: 6,
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1rem",
              background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
              boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
              "&:hover": {
                background: "linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)"
              }
            }}
            disabled={
              Object.values(formData.levels).some(
                (level) => !level.title || !level.description
              ) ||
              Object.values(formData.diaryLevels).some(
                (level) => !level.title || !level.description
              )
            }
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LevelsForm;
