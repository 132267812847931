import React, { useState } from "react";
import {
  Modal,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Paper,
  Checkbox,
  Box
} from "@mui/material";
import useSignedUrl from "../../utils/useSignedUrl";
import getInitials from "../../utils/getInitials";
import { editChallengeHandler } from "../../api/userApi";
import { fetchAllChallenges } from "../../features/users/userSlice";
import { useDispatch } from "react-redux";

const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      borderRadius="50%"
      height={40}
      width={40}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const LeaderboardModal = ({
  leaderboardData,
  open,
  selectedChallenge,
  setOpen,
  isFromSubAdmin,
  referenceId
}) => {
  const [loading, setLoading] = useState(false);
  const selectedData = leaderboardData?.find(
    (item) => item?._id === selectedChallenge?._id
  );
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleCheckboxChange = async (user) => {
    setLoading(true); // Optionally show a loading indicator
    try {
      await editChallengeHandler({
        userId: user?.userId,
        id: selectedChallenge?._id
      });
      dispatch(fetchAllChallenges(referenceId));
    } catch (error) {
      console.error("Error updating user exclusion status:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const getLeaderPosition = (index) => {
    switch (index) {
      case 0:
        return "🥇";
      case 1:
        return "🥈";
      case 2:
        return "🥉";
      default:
        return `${index + 1}`;
    }
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="leaderboard-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Paper
        elevation={24}
        sx={{
          width: "100%",
          maxWidth: 600,
          maxHeight: "90vh",
          overflow: "auto",
          borderRadius: 3,
          p: 3
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.main",
            mb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2
          }}
        >
          Leaderboard
        </Typography>

        <List sx={{ width: "100%" }}>
          {selectedData?.userPoints?.map((user, index) => (
            <ListItem
              key={user._id}
              sx={{
                mb: 2,
                display: "flex",
                gap: 2,
                backgroundColor:
                  index < 3 ? "rgba(0, 0, 0, 0.02)" : "transparent",
                borderRadius: 2,
                transition: "all 0.2s",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                  transform: "scale(1.02)"
                }
              }}
            >
              {isFromSubAdmin && (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(user, e.target.checked)}
                  checked={!user?.isExcluded}
                  disabled={loading} // Disable while API call is in progress
                />
              )}
              <ListItemAvatar
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                {!user?.isExcluded && (
                  <ListItemText
                    primary={
                      <Typography variant="h6" component="span">
                        {getLeaderPosition(index)}
                      </Typography>
                    }
                  />
                )}
                {user?.profilePic ? (
                  <ImageItem img={user?.profilePic} alt={user?.fullName} />
                ) : (
                  <Avatar sx={{ width: 40, height: 40 }}>
                    {getInitials(user?.fullName)}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body" component="span">
                    {user.fullName}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Chip
                  label={`${user.totalPoints} pts`}
                  color={index < 3 ? "primary" : "default"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem"
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Modal>
  );
};

export default LeaderboardModal;
