import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserCards } from "../../features/auth/authSlice";
import { selectCurrentUserCards } from "../../features/auth/authSelectors";
import { ReactComponent as CardIcon } from "../../assets/card.svg";
import { Add, AttachMoney, CreditCard, Person } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  Snackbar,
  Chip,
  Box,
  styled,
  Alert,
  Grid,
  Paper,
  Container,
  Card,
  CardContent,
  Stack
} from "@mui/material";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

import { createNewCard, removeCard, updateCard } from "../../api/authApi";
import DeleteUserModal from "../Modals/DeleteUserModal";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.12)"
  }
}));

const PaymentCard = styled(Card)(({ theme, isSelected }) => ({
  position: "relative",
  borderRadius: 24,
  background: isSelected
    ? "linear-gradient(135deg, #2E7D32 0%, #1B5E20 100%)"
    : "linear-gradient(135deg, #1E88E5 0%, #1565C0 100%)",
  color: "white",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  height: 240,
  cursor: "pointer",
  border: isSelected ? "3px solid #4CAF50" : "none",
  "&:hover": {
    transform: "scale(1.03) translateY(-8px)",
    boxShadow: "0 12px 48px rgba(0, 0, 0, 0.2)"
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 0.04)",
    backdropFilter: "blur(8px)",
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.12)"
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main
    }
  }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  padding: "0 !important",
  "&::before": {
    content: '""',
    position: "absolute",
    top: -40,
    right: -40,
    width: 200,
    height: 200,
    background:
      "radial-gradient(circle, rgba(46, 125, 50, 0.1) 0%, rgba(46, 125, 50, 0) 70%)",
    borderRadius: "50%",
    zIndex: 0
  }
}));

const cardStyle = {
  base: {
    color: "#424770",
    fontSize: "16px",
    fontFamily: '"Inter", sans-serif',
    "::placeholder": {
      color: "#aab7c4"
    },
    padding: "12px"
  },
  invalid: {
    color: "#e53935",
    iconColor: "#e53935"
  }
};

const CardLists = () => {
  const allCards = useSelector(selectCurrentUserCards);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [deleteCardModal, setDeleteCardModal] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: ""
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    dispatch(fetchUserCards());
  }, [dispatch]);

  useEffect(() => {
    if (allCards?.length) {
      const defaultCard = allCards.find((card) => card.isDefaultCard)?.id;
      setSelectedCardId(defaultCard);
    }
  }, [allCards]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (!stripe || !elements) return;

      const cardNumberElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: `${formData.firstName} ${formData.lastName}`
      });

      if (error) {
        setSnackbarMessage(error.message);
        setOpenSnackbar(true);
        return;
      }

      await createNewCard({ token: token.id });
      setIsAddingCard(false);
      setFormData({ firstName: "", lastName: "" });
      dispatch(fetchUserCards());

      setSnackbarMessage("Card added successfully!");
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to add card");
      setOpenSnackbar(true);
    }
  };

  const renderAddCardForm = () => (
    <Container maxWidth={"unset"} sx={{ padding: "0 !important" }}>
      <StyledPaper elevation={0} sx={{ p: 4, mt: 3 }}>
        <Box component="form" onSubmit={handleUpdate}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={3}>
                <CreditCard color="primary" sx={{ mr: 2 }} />
                <Typography variant="h6">Card Information</Typography>
              </Box>
              <Paper variant="outlined" sx={{ p: 2, mb: 3, borderRadius: 2 }}>
                <CardNumberElement options={{ style: cardStyle }} />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                <CardExpiryElement options={{ style: cardStyle }} />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                <CardCvcElement options={{ style: cardStyle }} />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={3}>
                <Person color="primary" sx={{ mr: 2 }} />
                <Typography variant="h6">Cardholder Information</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  onClick={() => setIsAddingCard(false)}
                  sx={{ borderRadius: 2 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formData.firstName || !formData.lastName}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#4caf50",
                    "&:hover": {
                      backgroundColor: "#43a047"
                    }
                  }}
                >
                  Add Card
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
    </Container>
  );

  const renderCardsList = () => (
    <Container maxWidth={"unset"} sx={{ padding: "0 !important" }}>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: "#4caf50",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <AttachMoney sx={{ fontSize: 24, color: "white" }} />
            </Box>
            <Typography variant="h6" fontWeight="bold" color="#4caf50">
              Payment Methods
            </Typography>
          </Stack>
          <Box display={"flex"} gap="20px" alignItems={"center"}>
            <Button
              disabled={
                allCards?.find((card) => card.isDefaultCard === true)?.id ===
                selectedCardId
              }
              onClick={async () => {
                await updateCard(selectedCardId);
                await dispatch(fetchUserCards());
              }}
              sx={{
                borderRadius: 2,
                px: 2,
                backgroundColor: "#4caf50",
                color: "#FFF",
                textTransform: "none",
                ":disabled": {
                  backgroundColor: "#38B65B",
                  color: "#9CDBAD",
                  pointerEvents: "none"
                }
              }}
            >
              Save Default
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsAddingCard(true)}
              sx={{
                borderRadius: 2,
                backgroundColor: "#4caf50",
                "&:hover": {
                  backgroundColor: "#43a047"
                }
              }}
            >
              Add New Card
            </Button>
          </Box>
        </Box>

        {allCards?.length ? (
          <Grid container spacing={3}>
            {allCards.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.id}>
                <PaymentCard
                  isSelected={card.id === selectedCardId}
                  onClick={() => setSelectedCardId(card.id)}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <CreditCard sx={{ fontSize: 40, opacity: 0.8 }} />
                      </Box>
                      {!card.isDefaultCard && (
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteCardModal(card.id);
                          }}
                          sx={{
                            m: 2,
                            color: "white",
                            borderColor: "rgba(255,255,255,0.5)",
                            "&:hover": {
                              borderColor: "white"
                            }
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>

                    <Typography variant="h6" sx={{ mb: 2 }}>
                      •••• {card.cardNumber.slice(-4)}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1, opacity: 0.8 }}>
                      {card.firstName} {card.lastName}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="caption" sx={{ opacity: 0.8 }}>
                        Expires: {card.expiryDate}
                      </Typography>
                      {card.isDefaultCard && (
                        <Chip
                          label="Default"
                          size="small"
                          sx={{
                            bgcolor: "rgba(255,255,255,0.2)",
                            color: "white"
                          }}
                        />
                      )}
                    </Box>
                  </CardContent>
                </PaymentCard>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={8}
          >
            <CardIcon sx={{ fontSize: 60, opacity: 0.5, mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No Payment Methods
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={3}>
              Add a payment method to manage your subscriptions
            </Typography>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsAddingCard(true)}
              sx={{
                borderRadius: 2,
                backgroundColor: "#4caf50",
                "&:hover": {
                  backgroundColor: "#43a047"
                }
              }}
            >
              Add Payment Method
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );

  return (
    <Box
      sx={{
        padding: { xs: 2, md: 4 },
        backgroundColor: "#FAFAFA",
        borderRadius: 4,
        boxShadow: "0 4px 24px rgba(0,0,0,0.06)",
        overflow: "hidden",
        position: "relative"
      }}
    >
      <StyledContainer maxWidth="">
        {isAddingCard ? renderAddCardForm() : renderCardsList()}
      </StyledContainer>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage?.includes("success") ? "success" : "error"}
          variant="filled"
          sx={{
            borderRadius: 3,
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)"
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <DeleteUserModal
        open={!!deleteCardModal}
        handleDelete={async () => {
          await removeCard(deleteCardModal);
          setDeleteCardModal(null);
          dispatch(fetchUserCards());
        }}
        handleClose={() => setDeleteCardModal(null)}
      />
    </Box>
  );
};

export default CardLists;
