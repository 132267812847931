import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Avatar,
  Chip,
  linearProgressClasses,
  Accordion,
  InputLabel,
  Switch
} from "@mui/material";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach-badge.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as BicepIcon } from "../../assets/bicep.svg";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DateRangePicker from "../Extra/DateRangePicker";
import DiaryTracker from "../Extra/DiaryTracker";
import { ReactComponent as TrophyIcon } from "../../assets/goal-trophy.svg";
import {
  selectAllDiaryExtraData,
  selectAllDiaryOutOf800g,
  selectAllDiaryOutOfCalories,
  selectAllDiaryOutOfProtein,
  selectAllDiaryStats
} from "../../features/diary/diarySelectors";
import { getCurrentUserDiaryStats } from "../../features/diary/diarySlice";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import getInitials from "../../utils/getInitials";
import useSignedUrl from "../../utils/useSignedUrl";
import useThemeColor from "../../utils/useTheme";
import { options800gms, optionsProteins } from "../../utils/mockWeightOptions";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";
import formatNumber from "../../utils/formatNumber";
import { selectLevelDetails } from "../../features/training/trainingSelectors";
import { getAllLevelDetails } from "../../features/training/trainingSlice";
import MuiNutritionCharts from "../Extra/Graph";

const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      borderRadius="50%"
      height={100}
      width={100}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, activeColor, background }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: activeColor
    }
  })
);
const includedMicroNutrientFields = [
  "calcium",
  "iron",
  "potassium",
  "sodium",
  "vitamin_a",
  "vitamin_c",
  "vitamin_d"
];

const nutrientFields = (data) => {
  if (data)
    return Object.entries(data).filter(([key, value]) => {
      return includedMicroNutrientFields.includes(key) && !isNaN(Number(value));
    });
};

const formattedNutrients = (data) => {
  return nutrientFields?.(data)?.map(([key, value]) => {
    const numericValue = parseFloat(value).toFixed(2) || 0;
    const isSmallValue = numericValue && numericValue < 1;
    return {
      name: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      value: isSmallValue ? numericValue * 1000 : numericValue,
      unit: isSmallValue ? "mg" : "g"
    };
  });
};
const StatsListModal = ({ open, setOpen }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState("7days");
  const [includeToday, setIncludeToday] = useState(true);
  const dispatch = useDispatch();
  const [levelName, setActiveDiaryLevel] = useState(open?.diaryLevelName);
  const extraData = useSelector(selectAllDiaryExtraData);
  const allStats = useSelector(selectAllDiaryStats);
  const total800g = useSelector(selectAllDiaryOutOf800g);
  const totalProteins = useSelector(selectAllDiaryOutOfProtein);
  const totalCalories = useSelector(selectAllDiaryOutOfCalories);
  const availableLevels = useSelector(selectLevelDetails) || [];
  // const levelName = open?.diaryLevelName;
  const handleClose = () => {
    setOpen({});
    setValue("7days");
  };
  useEffect(() => {
    dispatch(getAllLevelDetails());
  }, [dispatch]);

  useEffect(() => {
    if (open?.diaryLevelName) setActiveDiaryLevel(open?.diaryLevelName);
  }, [open]);
  useEffect(() => {
    if (
      open.userId &&
      ((value === "custom" && startDate && endDate) || value !== "custom")
    )
      dispatch(
        getCurrentUserDiaryStats({
          levelName: levelName,
          userId: open.userId,
          timeFrame: value,
          includeToday,
          date: dayjs().format("YYYY-MM-DD"),
          ...(value === "custom" && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate).format("YYYY-MM-DD")
          })
        })
      );
  }, [
    dispatch,
    levelName,
    value,
    includeToday,
    open.userId,
    startDate,
    endDate
  ]);

  const calculateAverage800gms = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalEntry800g || 0),
      0
    );
    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.metric_serving_amount
            : sum / allStats?.length
        ) || 0,
      // average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOf800g || total800g
    };
  }, [allStats, total800g]);

  const calculateAverageProtein = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );

    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.protein
            : sum / allStats?.length
        ) || 0,
      // average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOfProtein || totalProteins
    };
  }, [allStats, totalProteins]);

  const calculateAverageCalories = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );
    return {
      average: Math.round(extraData?.averagedSummaries?.calories || 0) || 0,
      total: allStats[0]?.outOfCalories || totalCalories
    };
  }, [allStats, totalCalories]);

  const styles = useThemeColor();

  return (
    <>
      {/* Modal */}
      <Dialog
        sx={{
          width: "100%",
          ".MuiPaper-root": {
            maxWidth: "100%"
          }
        }}
        open={Object.keys(open).length}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Stats
          </Typography>
          {/* <Button onClick={handleClose} style={{ float: "right" }}>
            Close
          </Button> */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display="flex" gap="20px" padding={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              px={2}
              py={3}
              borderRadius={2}
              alignItems="center"
              sx={{ background: "#F3F4F6", maxWidth: "30%" }}
            >
              {open?.profilePic ? (
                <ImageItem img={open?.profilePic} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, mr: 1, fontSize: 29 }}>
                  {open.fullName
                    ? getInitials(open.fullName)
                    : getInitials(open.displayName)}
                </Avatar>
              )}
              <Typography variant="h6" fontWeight={600} fontSize={16}>
                {open?.fullName}
              </Typography>
              <Chip
                label={"User"}
                sx={{
                  backgroundColor: "#FCECD3",
                  fontSize: "12px",
                  color: "#F1B44C"
                }}
              />
              {open?.approvedMealsCount ||
              open?.completedLevel?.length ||
              (open?.isAdmin && open?.userType === "Coach") ||
              open?.isConsistent ||
              open?.maxStreak ? (
                <Box
                  display="flex"
                  mt={2}
                  ml={2}
                  gap={2}
                  alignItems="center"
                  overflow="auto"
                  flexWrap="wrap" // Allow wrapping to the next line
                  justifyContent="center" // Align items to the left
                  sx={{
                    "::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                >
                  {open?.approvedMealsCount ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <ChefIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Chef
                      </Typography>
                      <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.approvedMealsCount}
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level1" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl1Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 1
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level2" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl2Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 2
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level3" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl3Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 3
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.isAdmin && open?.userType === "Coach" ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Coach
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Three Pillars Method® Coach. Fulfilled the
                    //         requirements of the Coaches
                    //         Masterclass and affiliation.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <CoachIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Coach
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {open?.maxStreak ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Streaking
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Represents your greatest streak of diary logging
                    //         days in a row.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    // <Box>
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <ConstantIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Streaking
                      </Typography>
                      <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          padding:
                            String(open?.maxStreak)?.length === 1
                              ? "1px 7px"
                              : "1px 4px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.maxStreak}
                      </Typography>
                    </Box>
                  ) : (
                    //   <Typography>Streaking</Typography>
                    // </Box>
                    // </Tooltip>
                    <></>
                  )}
                  {open?.isConsistent ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Consistency
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Appears when you've logged for at least 11 of the
                    //         last 14 days.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    //   {" "}
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <BicepIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Consistency
                      </Typography>
                      {/* <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          padding:
                            String(open?.isConsistent)?.length === 1
                              ? "1px 7px"
                              : "1px 4px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.isConsistent}
                      </Typography> */}
                    </Box>
                  ) : (
                    // </Tooltip>
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={3} width="100%">
              {/* Dropdown & Custom Date Picker */}
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={"column"}
                gap={2}
              >
                <FormControl sx={{ width: "60%" }}>
                  <InputLabel>Level</InputLabel>
                  <Select
                    value={levelName}
                    label="Level"
                    onChange={(e) => setActiveDiaryLevel(e.target.value)}
                  >
                    {availableLevels?.map((item) => (
                      <MenuItem value={item.levelName}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box display="flex" justifyContent={"space-between"}>
                  <Typography variant="h6" fontWeight="bold">
                    Diet Statistics
                  </Typography>
                  {value === "today" ? (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      // flexDirection={isMobile ? "column" : "row"}
                    ></Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      // flexDirection={isMobile ? "column" : "row"}
                    >
                      <Typography color={styles.color} fontSize={"13px"}>
                        Include Today
                      </Typography>
                      <Switch
                        checked={includeToday}
                        onChange={(e) => {
                          setIncludeToday(e.target.checked);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <FormControl
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    marginTop: "15px",
                    svg: { fill: "#1C1C1C" },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#F5F5F5" },
                      "&:hover fieldset": { borderColor: "#1C1C1C" },
                      "&.Mui-focused fieldset": { borderColor: "#1C1C1C" }
                    }
                  }}
                >
                  <Select
                    labelId="range-label"
                    name="range"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    sx={{ color: "#1C1C1C" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="7days">7 Days</MenuItem>
                    <MenuItem value="30days">30 Days</MenuItem>
                    <MenuItem value="custom">Custom Time</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {value === "custom" && (
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              )}

              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  borderRadius: "16px"
                }}
              >
                <DiaryTracker
                  title={"#800gChallenge"}
                  nonEditable
                  levelName={levelName}
                  strokeColor={"#F6541C"}
                  value={calculateAverage800gms?.average}
                  maxValue={calculateAverage800gms?.total}
                  height={levelName === "level2" ? 210 : 250}
                  width={levelName === "level2" ? 300 : 350}
                />
                {levelName !== "level1" && (
                  <DiaryTracker
                    title={"Protein"}
                    nonEditable
                    levelName={levelName}
                    strokeColor={"#368AD0"}
                    value={calculateAverageProtein?.average}
                    maxValue={calculateAverageProtein?.total}
                    height={levelName === "level2" ? 210 : 250}
                    width={levelName === "level2" ? 300 : 350}
                  />
                )}
                {levelName === "level3" && (
                  <DiaryTracker
                    nonEditable
                    isCalories
                    strokeColor={"#FF9431"}
                    levelName={levelName}
                    value={calculateAverageCalories?.average}
                    maxValue={calculateAverageCalories?.total}
                    title={"Calories"}
                    heightX={levelName === "level2" ? 210 : 250}
                    widthX={levelName === "level2" ? 300 : 350}
                  />
                )}
              </Box>
              {levelName === "level3" ? (
                <>
                  {" "}
                  {/* <Box
                    width="100%"
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={0.6}
                    borderRadius="8px"
                    padding={"18px"}
                    sx={{ background: styles.lightGreyColor }}
                  >
                    <Box
                      width="100%"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography fontSize="13px">Fat</Typography>
                      <Typography fontSize="13px">
                        {formatNumber(
                          Number(extraData?.averagedSummaries?.fat)?.toFixed(2)
                        ) || 0}
                        g
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography fontSize="13px">Carbohydrate</Typography>
                      <Typography fontSize="13px">
                        {formatNumber(
                          Number(
                            extraData?.averagedSummaries?.carbohydrate
                          )?.toFixed(2)
                        ) || 0}
                        g
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography fontSize="13px">Fiber</Typography>
                      <Typography fontSize="13px">
                        {formatNumber(
                          Number(extraData?.averagedSummaries?.fiber)?.toFixed(
                            2
                          )
                        ) || 0}
                        g
                      </Typography>
                    </Box>
                  </Box> */}
                  <Box>
                    <Typography fontSize="15px" color={styles.color}>
                      Three Pillars Method
                    </Typography>
                    <Box
                      mt={1}
                      width="100%"
                      borderRadius="8px"
                      padding={"18px"}
                      sx={{ background: styles.lightGreyColor }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"start"}
                      >
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                          display="flex"
                          variant="body2"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#F6541C" }}
                            borderRadius="50%"
                          />
                          800g
                        </Typography>
                        <Box
                          width="100%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.totalSummaries?.entry800gSummary
                                    ?.calories
                                )?.toFixed(2)
                              ) || 0}{" "}
                              Cals
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (
                                  (formatNumber(
                                    Number(
                                      extraData?.totalSummaries
                                        ?.entry800gSummary?.calories
                                    ) || 0
                                  ) /
                                    (calculateAverageCalories?.average || 1)) *
                                  100
                                )?.toFixed(2)
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.entry800gSummary
                                    ?.protein
                                )?.toFixed(2)
                              ) || 0}
                              g Protein
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.entry800gSummary
                                      ?.protein
                                  ) || 0
                                ) / calculateAverageProtein?.average || 0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.entry800gSummary
                                    ?.carbohydrate
                                )?.toFixed(2)
                              ) || 0}
                              g Carbs
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.entry800gSummary
                                      ?.carbohydrate
                                  ) || 0
                                ) /
                                  extraData?.averagedSummaries?.carbohydrate ||
                                  0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.entry800gSummary
                                    ?.fiber
                                )?.toFixed(2)
                              ) || 0}
                              g Fiber
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.entry800gSummary
                                      ?.fiber
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fiber || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.entry800gSummary
                                    ?.fat
                                )?.toFixed(2)
                              ) || 0}
                              g Fat
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.entry800gSummary
                                      ?.fat
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fat || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider
                        sx={{
                          background: "#8E8E8E",
                          width: "100%",
                          m: "10px 0"
                        }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"start"}
                      >
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                          display="flex"
                          variant="body2"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#368AD0" }}
                            borderRadius="50%"
                          />
                          Protein
                        </Typography>
                        <Box
                          width="100%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.totalSummaries?.proteinSummary
                                    ?.calories
                                )?.toFixed(2)
                              ) || 0}{" "}
                              Cals
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.calories
                                  ) || 0
                                ) / calculateAverageCalories?.average || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.protein
                                )?.toFixed(2)
                              ) || 0}
                              g Protein
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.protein
                                  ) || 0
                                ) / calculateAverageProtein?.average || 0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.carbohydrate
                                )?.toFixed(2)
                              ) || 0}
                              g Carbs
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.carbohydrate
                                  ) || 0
                                ) /
                                  extraData?.averagedSummaries?.carbohydrate ||
                                  0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.fiber
                                )?.toFixed(2)
                              ) || 0}
                              g Fiber
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.fiber
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fiber || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.fat
                                )?.toFixed(2)
                              ) || 0}
                              g Fat
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.fat
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fat || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider
                        sx={{
                          background: "#8E8E8E",
                          width: "100%",
                          m: "10px 0"
                        }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"start"}
                      >
                        <Typography
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                          display="flex"
                          variant="body2"
                          fontWeight="bold"
                          color={styles.color}
                        >
                          <Box
                            height="8px"
                            width="8px"
                            sx={{ background: "#FF9431" }}
                            borderRadius="50%"
                          />
                          Other
                        </Typography>
                        <Box
                          width="100%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.totalSummaries?.otherFoodSummary
                                    ?.calories
                                )?.toFixed(2)
                              ) || 0}{" "}
                              Cals
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.otherFoodSummary
                                      ?.calories
                                  ) || 0
                                ) / calculateAverageCalories?.average || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.otherFoodSummary
                                    ?.protein
                                )?.toFixed(2)
                              ) || 0}
                              g Protein
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.otherFoodSummary
                                      ?.protein
                                  ) || 0
                                ) / calculateAverageProtein?.average || 0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.otherFoodSummary
                                    ?.carbohydrate
                                )?.toFixed(2)
                              ) || 0}
                              g Carbs
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.otherFoodSummary
                                      ?.carbohydrate
                                  ) || 0
                                ) /
                                  extraData?.averagedSummaries?.carbohydrate ||
                                  0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.otherFoodSummary
                                    ?.fiber
                                )?.toFixed(2)
                              ) || 0}
                              g Fiber
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.otherFoodSummary
                                      ?.fiber
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fiber || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  +extraData?.totalSummaries?.otherFoodSummary
                                    ?.fat
                                )?.toFixed(2)
                              ) || 0}
                              g Fat
                            </Typography>
                            <Typography fontSize="12px">
                              {" "}
                              {Math.round(
                                (formatNumber(
                                  Number(
                                    extraData?.totalSummaries?.otherFoodSummary
                                      ?.fat
                                  ) || 0
                                ) / extraData?.averagedSummaries?.fat || 0) *
                                  100
                              )}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontSize="15px" color={styles.color}>
                      Macronutrients
                    </Typography>
                    <Box
                      mt={1}
                      width="100%"
                      borderRadius="8px"
                      padding={"18px"}
                      sx={{ background: styles.lightGreyColor }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"start"}
                        gap="10px"
                      >
                        <Box
                          width="100%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {calculateAverageCalories?.average} Cals
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(calculateAverageCalories?.average) || 0
                                ) / calculateAverageCalories?.total || 0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {calculateAverageProtein?.average}g Protein
                            </Typography>
                            <Typography fontSize="12px">
                              {Math.round(
                                (formatNumber(
                                  Number(calculateAverageProtein?.average) || 0
                                ) / calculateAverageProtein?.total || 0) * 100
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.averagedSummaries?.carbohydrate
                                )?.toFixed(2)
                              ) || 0}
                              g Carbs
                            </Typography>
                            <Typography fontSize="12px">33%</Typography>
                          </Box>
                          <Box mr={2}>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.averagedSummaries?.fat
                                )?.toFixed(2)
                              ) || 0}
                              g Fat
                            </Typography>
                            <Typography fontSize="12px">12%</Typography>
                          </Box>
                        </Box>
                        <Box
                          width="100%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box width={"90px"}></Box>
                          <Box width={"90px"}></Box>
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.averagedSummaries?.fiber
                                )?.toFixed(2)
                              ) || 0}
                              g Fiber
                            </Typography>
                          </Box>{" "}
                          <Box>
                            <Typography
                              fontSize="12px"
                              color={styles.lightBlack}
                            >
                              {formatNumber(
                                Number(
                                  extraData?.averagedSummaries?.saturated_fat
                                )?.toFixed(2)
                              ) || 0}
                              g Saturated Fat
                            </Typography>
                            <Typography fontSize="12px" textAlign="center">
                              33%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {extraData?.averagedSummaries &&
                  Object.keys(extraData?.averagedSummaries)?.length ? (
                    <Box>
                      <Typography fontSize="15px" color={styles.color}>
                        Select Micronutrients
                      </Typography>
                      <Box
                        mt={1}
                        width="100%"
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"center"}
                        gap={0.6}
                        borderRadius="8px"
                        padding={"18px"}
                        sx={{ background: styles.lightGreyColor }}
                      >
                        {formattedNutrients(extraData?.averagedSummaries)?.map(
                          (item) => (
                            <Box
                              width="100%"
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography fontSize="13px">
                                {item.name}
                              </Typography>
                              <Typography fontSize="13px">
                                {item.value + item.unit}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <Box
                display="flex"
                flexDirection="column"
                width={"100%"}
                gap={2}
                mb={2}
                sx={{
                  overflowY: "auto",
                  maxHeight: "295px",
                  "::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
              >
                {levelName !== "level3" ? (
                  allStats?.map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded === index} // Controls whether the Accordion is open
                      onChange={() =>
                        setExpanded(expanded === index ? false : index)
                      }
                      sx={{
                        ":before": {
                          position: "relative"
                        },
                        boxShadow: "none"
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        sx={{
                          ...(levelName === "level1" && { width: "600px" }),
                          background: styles.modBackgroundBlocks,
                          borderRadius: "16px",
                          p: 3
                        }}
                      >
                        {/* Date and Goal Achieved */}
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" gap="10px" alignItems="center">
                            <Typography fontWeight={600} fontSize={"18px"}>
                              {dayjs(item?.currentDayDate).format("MMMM D")}
                            </Typography>
                            {+item.totalEntry800g >= +item.outOf800g &&
                              (levelName === "level2"
                                ? +item.totalProtein >= +item.outOfProtein
                                : true) && (
                                <Box
                                  display="flex"
                                  gap="4px"
                                  alignItems="center"
                                  sx={{
                                    background: "#F1B44C",
                                    px: 1,
                                    border: `1px solid ${styles.color}`,
                                    borderRadius: "100px"
                                  }}
                                >
                                  <TrophyIcon />
                                  <Typography color="#14142B" fontSize={"12px"}>
                                    Goal Achieved
                                  </Typography>
                                </Box>
                              )}
                          </Box>

                          {/* Expand More Icon */}
                          {levelName !== "level3" && (
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => {
                                setExpanded(expanded === index ? false : index);
                              }}
                            >
                              {expanded === index ? (
                                <ExpandLess sx={{ cursor: "pointer" }} />
                              ) : (
                                <ExpandMore sx={{ cursor: "pointer" }} />
                              )}
                            </IconButton>
                          )}
                        </Box>

                        {/* Content Section */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Typography
                            fontWeight={600}
                            color="#F6541C"
                            fontSize="16px"
                          >
                            800g
                          </Typography>
                          <Box>
                            <Typography fontWeight={600} fontSize="16px">
                              {levelName === "level3"
                                ? `${
                                    item?.entry800gSummary
                                      ?.metric_serving_amount || 0
                                  }/${total800g}g`
                                : `${item.totalEntry800g || 0}/${
                                    item.outOf800g
                                  }g`}
                            </Typography>
                          </Box>
                        </Box>
                        <BorderLinearProgress
                          activeColor="#F6541C"
                          background={"#D1D5DB"}
                          variant="determinate"
                          value={
                            levelName === "level3"
                              ? ((item?.entry800gSummary
                                  ?.metric_serving_amount || 0) >= total800g
                                  ? 1
                                  : Math.round(
                                      item?.entry800gSummary
                                        ?.metric_serving_amount || 0
                                    ) / total800g) * 100
                              : ((item.totalEntry800g || 0) >= item.outOf800g
                                  ? 1
                                  : (item.totalEntry800g || 0) /
                                    item.outOf800g) * 100
                          }
                        />

                        {/* Protein Progress for Level 2 */}
                        {levelName !== "level1" && (
                          <>
                            <Box
                              display="flex"
                              mt={1}
                              justifyContent="space-between"
                            >
                              <Typography
                                fontWeight={600}
                                color="#368AD0"
                                fontSize="16px"
                              >
                                Protein
                              </Typography>
                              <Box>
                                <Typography fontWeight={600} fontSize="16px">
                                  {levelName === "level3"
                                    ? `${Math.round(
                                        item?.entry800gSummary?.protein +
                                          item?.proteinSummary?.protein +
                                          item?.otherFoodSummary?.protein || 0
                                      )}/${totalProteins}g`
                                    : `${item.totalProtein || 0}/${
                                        item.outOfProtein
                                      }g`}
                                </Typography>
                              </Box>
                            </Box>
                            <BorderLinearProgress
                              activeColor="#368AD0"
                              background={"#D1D5DB"}
                              variant="determinate"
                              value={
                                levelName === "level3"
                                  ? ((item?.entry800gSummary?.protein +
                                      item?.proteinSummary?.protein +
                                      item?.otherFoodSummary?.protein ||
                                      0 ||
                                      0) >= totalProteins
                                      ? 1
                                      : (item?.entry800gSummary?.protein +
                                          item?.proteinSummary?.protein +
                                          item?.otherFoodSummary?.protein ||
                                          0 ||
                                          0) / totalProteins) * 100
                                  : ((item.totalProtein || 0) >=
                                    item.outOfProtein
                                      ? 1
                                      : (item.totalProtein || 0) /
                                        item.outOfProtein) * 100
                              }
                            />
                          </>
                        )}
                        {levelName === "level3" && (
                          <>
                            {" "}
                            <Box
                              display="flex"
                              mt={1}
                              justifyContent="space-between"
                            >
                              <Typography
                                fontWeight={600}
                                color="#FF9431"
                                fontSize="16px"
                              >
                                {" "}
                                Calories
                              </Typography>
                              <Box>
                                <Typography fontWeight={600} fontSize="16px">
                                  {Math.round(
                                    item?.entry800gSummary?.calories +
                                      item?.proteinSummary?.calories +
                                      item?.otherFoodSummary?.calories ||
                                      0 ||
                                      0
                                  )}
                                  /{totalCalories}
                                </Typography>
                              </Box>
                            </Box>
                            <BorderLinearProgress
                              activeColor="#FF9431"
                              background={"#D1D5DB"}
                              variant="determinate"
                              value={
                                ((item?.entry800gSummary?.calories +
                                  item?.proteinSummary?.calories +
                                  item?.otherFoodSummary?.calories ||
                                  0 ||
                                  0) >= totalCalories
                                  ? 1
                                  : (item?.entry800gSummary?.calories +
                                      item?.proteinSummary?.calories +
                                      item?.otherFoodSummary?.calories ||
                                      0 ||
                                      0) / totalCalories) * 100
                              }
                            />
                          </>
                        )}

                        {/* Collapsible Content */}
                        {expanded === index && (
                          <Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mt={2}
                              borderTop={"1px solid lightgray"}
                              pt={2}
                            >
                              <Typography
                                fontWeight={600}
                                color="#F6541C"
                                fontSize="16px"
                              >
                                800g
                              </Typography>
                              <Box>
                                <Typography fontWeight={600} fontSize="16px">
                                  {item.totalEntry800g || 0}/{item.outOf800g}g
                                </Typography>
                              </Box>
                            </Box>
                            {item?.entry800g?.map((data) => (
                              <Box
                                py={0.4}
                                width={"100%"}
                                display="flex"
                                justifyContent="space-between"
                                key={data.key}
                              >
                                <Box>
                                  <Typography
                                    fontSize={13}
                                    color={styles.color}
                                    textTransform={"capitalize"}
                                  >
                                    {options800gms?.find(
                                      (option) => option.value === data.name
                                    )?.label ?? data.name}
                                  </Typography>
                                  <Typography
                                    fontSize={10}
                                    color={styles.disabledColor}
                                  >
                                    {data.time}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems={"center"}>
                                  <Typography fontSize={13} fontWeight={400}>
                                    {convertItemQuantityToGrams(data)?.quantity}
                                    g
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                            {levelName === "level2" && (
                              <>
                                <Box
                                  display="flex"
                                  mt={1}
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    fontWeight={600}
                                    color="#368AD0"
                                    fontSize="16px"
                                  >
                                    Protein
                                  </Typography>
                                  <Box>
                                    <Typography
                                      fontWeight={600}
                                      fontSize="16px"
                                    >
                                      {item.totalProtein || 0}/
                                      {item.outOfProtein}g
                                    </Typography>
                                  </Box>
                                </Box>
                                {item?.protein?.map((data) => (
                                  <Box
                                    width={"100%"}
                                    display="flex"
                                    py={0.4}
                                    justifyContent="space-between"
                                    key={data.key}
                                  >
                                    <Box>
                                      <Typography
                                        fontSize={13}
                                        color={styles.color}
                                        textTransform={"capitalize"}
                                      >
                                        {optionsProteins?.find(
                                          (option) => option.value === data.name
                                        )?.label ?? data.name}
                                      </Typography>
                                      <Typography
                                        fontSize={10}
                                        color={styles.disabledColor}
                                      >
                                        {data.time}
                                      </Typography>
                                    </Box>
                                    <Box display="flex" alignItems={"center"}>
                                      <Typography
                                        fontSize={13}
                                        fontWeight={400}
                                      >
                                        {
                                          convertItemQuantityToGrams(data)
                                            ?.quantity
                                        }
                                        g
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Accordion>
                  ))
                ) : allStats?.length ? (
                  <MuiNutritionCharts
                    total800g={total800g}
                    totalProteins={totalProteins}
                    totalCalories={totalCalories}
                    data={allStats}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StatsListModal;
