import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Stack,
  List,
  ListItem,
  Button,
  Chip
} from "@mui/material";
import {
  PeopleAlt,
  SportsGymnastics,
  Person,
  ArrowUpward,
  Business,
  AttachMoney,
  MonetizationOn,
  Timeline,
  Handshake
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDataAdmin } from "../../features/auth/authSlice";
import dayjs from "dayjs";
import { selectDashboardData } from "../../features/auth/authSelectors";

const SuperAdminMainDashboard = () => {
  const [expandedSections, setExpandedSections] = useState({
    gyms: false,
    coaches: false,
    companies: false,
    affiliates: false
  });
  const dashboardData = useSelector(selectDashboardData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardDataAdmin(dayjs().format("YYYY-MM-DD")));
  }, [dispatch]);

  const StatCard = ({ title, value, icon: Icon, color, secondaryText }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        background: `linear-gradient(135deg, ${color}15, ${color}05)`,
        borderRadius: 4,
        position: "relative",
        overflow: "hidden",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: 3,
          "& .icon-wrapper": {
            transform: "rotate(10deg)"
          }
        }
      }}
    >
      <Box sx={{ position: "relative", zIndex: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              color="text.secondary"
              variant="body2"
              fontWeight="bold"
            >
              {title}
            </Typography>
            <Typography variant="h4" fontWeight="bold" sx={{ my: 1, color }}>
              {value}
            </Typography>
            {secondaryText && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <ArrowUpward sx={{ fontSize: 16, color: "success.main" }} />
                <Typography
                  variant="caption"
                  color="success.main"
                  fontWeight={500}
                >
                  {secondaryText}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box
            className="icon-wrapper"
            sx={{
              p: 2,
              borderRadius: 2,
              bgcolor: color,
              transition: "transform 0.2s"
            }}
          >
            <Icon sx={{ fontSize: 30, color: "white" }} />
          </Box>
        </Stack>
      </Box>
    </Paper>
  );

  const SectionCard = ({
    title,
    items,
    expanded,
    onToggle,
    icon: Icon,
    color
  }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 4,
        background: `linear-gradient(135deg, ${color}08, ${color}02)`,
        border: `1px solid ${color}15`
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              p: 1.5,
              borderRadius: 2,
              bgcolor: color
            }}
          >
            <Icon sx={{ fontSize: 24, color: "white" }} />
          </Box>
          <Typography variant="h6" fontWeight="bold">
            {title} ({items?.length})
          </Typography>
        </Stack>
      </Stack>
      <List>
        {items?.slice(0, expanded ? items?.length : 3).map((item, index) => (
          <ListItem
            key={index}
            sx={{
              py: 1,
              px: 2,
              my: 0.5,
              borderRadius: 2,
              bgcolor: "background.paper",
              "&:hover": { bgcolor: `${color}08` }
            }}
          >
            <Typography variant="body2">
              {item?.name} ( {item?.userCount} )
            </Typography>
          </ListItem>
        ))}
      </List>
      {items?.length > 3 && (
        <Button
          onClick={onToggle}
          sx={{
            mt: 2,
            color: color,
            "&:hover": { bgcolor: `${color}15` }
          }}
        >
          {expanded ? "See Less" : `See More (${items?.length - 3})`}
        </Button>
      )}
    </Paper>
  );

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Custom Total Users Block */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              height: "100%",
              borderRadius: 4,
              background: "linear-gradient(135deg, #2196f308, #2196f302)",
              border: "1px solid #2196f315"
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h4" fontWeight="bold">
                {dashboardData?.totalUsers} Total Users
              </Typography>
              <IconButton sx={{ bgcolor: "primary.main", color: "white" }}>
                <PeopleAlt />
              </IconButton>
            </Stack>
            {/* <Stack spacing={1}>
              <Typography variant="h6" color="text.secondary">
                Level 1 (200)
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Level 2 (40)
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Level 3 (10)
              </Typography>
            </Stack> */}

            <List>
              {dashboardData?.levelWiseUserCounts?.map((data, index) => (
                <ListItem
                  key={index}
                  sx={{
                    py: 1.5,
                    px: 2,
                    my: 0.5,
                    borderRadius: 2,
                    bgcolor: "background.paper",
                    "&:hover": { bgcolor: "#2196f308" }
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {data?.levelName} ( {data?.userCount} )
                    </Typography>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Sales Stat Blocks */}
        <Grid
          item
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            gap: 2,
            ml: "auto"
          }}
        >
          <StatCard
            title="Sales this Month"
            value={
              "$" +
              (dashboardData?.sales?.totalSalesThisMonth
                ?.toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0)
            }
            icon={MonetizationOn}
            color="#4caf50"
          />
          <StatCard
            title="Sales this Year"
            value={
              "$" +
              (dashboardData?.sales?.totalSalesThisYear
                ?.toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0)
            }
            icon={Timeline}
            color="#e91e63"
          />
        </Grid>

        {/* Section Cards */}
        <Grid item xs={12} md={3}>
          <SectionCard
            title="Gyms"
            items={dashboardData?.gymWiseUserCounts}
            expanded={expandedSections.gyms}
            onToggle={() =>
              setExpandedSections((prev) => ({ ...prev, gyms: !prev.gyms }))
            }
            icon={SportsGymnastics}
            color="#2196f3"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SectionCard
            title="Coaches"
            items={dashboardData?.coachWiseUserCounts}
            expanded={expandedSections.coaches}
            onToggle={() =>
              setExpandedSections((prev) => ({
                ...prev,
                coaches: !prev.coaches
              }))
            }
            icon={Person}
            color="#4caf50"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SectionCard
            title="Companies"
            items={dashboardData?.companyWiseUserCounts}
            expanded={expandedSections.companies}
            onToggle={() =>
              setExpandedSections((prev) => ({
                ...prev,
                companies: !prev.companies
              }))
            }
            icon={Business}
            color="#ff9800"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SectionCard
            title="Affiliates"
            items={dashboardData?.affiliateWiseUserCounts} // Changed to affiliate data
            expanded={expandedSections.affiliates} // Changed to affiliates state
            onToggle={() =>
              setExpandedSections((prev) => ({
                ...prev,
                affiliates: !prev.affiliates // Changed to affiliates toggle
              }))
            }
            icon={Handshake} // Changed to Handshake icon for affiliates
            color="#9c27b0" // Changed to purple to differentiate from other sections
          />
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: 4,
              background: "linear-gradient(135deg, #e91e6308, #e91e6302)",
              border: "1px solid #e91e6315"
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: "#e91e63"
                  }}
                >
                  <AttachMoney sx={{ fontSize: 24, color: "white" }} />
                </Box>
                <Typography variant="h6" fontWeight="bold">
                  Recent Transactions
                </Typography>
              </Stack>
            </Stack>
            <List sx={{ width: "100%", p: 0 }}>
              {dashboardData?.allTransactions?.map((transaction, index) => (
                <ListItem
                  key={index}
                  sx={{
                    py: 1.5,
                    px: 2,
                    my: 0.5,
                    borderRadius: 2,
                    bgcolor: "background.paper",
                    "&:hover": { bgcolor: "#e91e6308" }
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    spacing={2} // Consistent spacing between elements
                  >
                    {/* Left side - Product Description */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{ flex: 1 }} // Takes available space
                    >
                      {transaction?.product?.[0]?.description ||
                        "Retrial of payment Failed"}
                    </Typography>

                    {/* Right side - Date, Amount, and Status */}
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={3}
                      sx={{ minWidth: "300px" }} // Fixed width for consistent alignment
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ minWidth: "80px" }} // Fixed width for date
                      >
                        {transaction?.product?.[0]?.purchaseDate}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={
                          transaction.status?.includes("succe")
                            ? "success.main"
                            : "error.main"
                        }
                        fontWeight={500}
                        sx={{ minWidth: "80px" }} // Fixed width for amount
                      >
                        ${transaction.amount}
                      </Typography>
                      <Chip
                        label={
                          transaction.status?.includes("succe")
                            ? "Success"
                            : "Failed"
                        }
                        sx={
                          transaction.status?.includes("succe")
                            ? {
                                backgroundColor: "#D7F0DE",
                                fontSize: "12px",
                                color: "#38B65B"
                              }
                            : {
                                backgroundColor: "#FDD9CD",
                                fontSize: "12px",
                                color: "#F6541C"
                              }
                        }
                      />
                    </Stack>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuperAdminMainDashboard;
