import createApi from "../utils/axiosInstance";

export const getAllUsers = async (
  currentTab,
  page,
  limit,
  search,
  trainingLevelId,
  referenceId,
  sortBy,
  order
) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-all-users?page=${page}&limit=${limit}&search=${search}${
        currentTab ? `&type=${currentTab}` : ""
      }${trainingLevelId ? `&trainingLevelId=${trainingLevelId}` : ""}${
        referenceId ? `&referenceId=${referenceId}` : ""
      }${sortBy ? `&sortBy=${sortBy}&order=${order}` : ""}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllCoupons = async () => {
  try {
    const api = createApi(true);
    const response = await api.get(`/payment/coupon/get-coupons`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const getAllChallenges = async (id = "") => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/challenges/get-challenge${id ? `?referenceId=${id}` : ""}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting challenges failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addnewChallenge = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/admin/challenges/add-challenge`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Adding challenge failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const editChallengeHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/admin/challenges/edit-challenge/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Editing challenge failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteChallengeHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/admin/challenges/delete-challenge/${data.id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Deleting challenge failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteCouponHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/payment/coupon/remove-coupon/${data.id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Deleting coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const addnewCoupon = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/payment/coupon/add-coupon`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Adding coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const editCouponHandler = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.put(
      `/payment/coupon/edit-coupon/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Editing coupons failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getUsersByType = async (page, limit, search, sortBy, order) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-admin-user-list?page=${page}&limit=${limit}&search=${search}${
        sortBy ? `&sortBy=${sortBy}&order=${order}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateCode = async (code, id) => {
  try {
    const api = createApi(true);
    const response = await api.put(`/admin/user/update-code/${id}`, { code });
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteUserHandler = async (userId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(`/admin/user/delete-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
