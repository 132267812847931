export const selectAllUsers = (state) => state.users.users;
export const selectTotalUsers = (state) => state.users.total;
export const selectOccupiedSeats = (state) => state.users.occupiedSeats;
export const selectCurrentPage = (state) => state.users.page;
export const selectPendingMealsCount = (state) => state.users.pendingMealsCount;
export const selectLimit = (state) => state.users.limit;
export const selectUsersLoading = (state) => state.users.loading;
export const selectUsersError = (state) => state.users.error;
export const selectUsersCoupons = (state) => state.users.coupons;
export const selectUsersChallenges = (state) => state.users.challenges;
