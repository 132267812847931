import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Paper,
  Grid,
  useTheme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import useThemeColor from "../../utils/useTheme";

const MuiNutritionCharts = ({
  data,
  total800g,
  totalProteins,
  totalCalories
}) => {
  const theme = useTheme();

  const processData = (rawData) => {
    return rawData.map((day) => ({
      date: dayjs(day.currentDayDate).valueOf(),
      entry800g: day.entry800gSummary?.metric_serving_amount || 0,
      protein:
        (day.entry800gSummary?.protein || 0) +
        (day.proteinSummary?.protein || 0) +
        (day.otherFoodSummary?.protein || 0),
      calories:
        (day.entry800gSummary?.calories || 0) +
        (day.proteinSummary?.calories || 0) +
        (day.otherFoodSummary?.calories || 0)
    }));
  };

  const defaultChartConfig = [
    {
      title: "800g",
      dataKey: "entry800g",
      color: "#F6541C",
      target: total800g,
      unit: "g",
      description: "Daily fruit & vegetable intake"
    },
    {
      title: "Protein",
      dataKey: "protein",
      color: "#368AD0",
      target: totalProteins,
      unit: "g",
      description: "Total protein consumption"
    },
    {
      title: "Calories",
      dataKey: "calories",
      color: "#FF9431",
      target: totalCalories,
      unit: "cal",
      description: "Total calories consumed"
    }
  ];

  const processedData = processData(data || []);

  const getProgress = (data, config) => {
    const lastValue = data[data.length - 1][config.dataKey];
    const previousValue = data[data.length - 2]?.[config.dataKey] || 0;
    const change = ((lastValue - previousValue) / previousValue) * 100;
    return {
      value: lastValue,
      change: change || 0,
      isPositive: change > 0
    };
  };
  const styles = useThemeColor();
  const dates = processedData.map((item) => item.date);
  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);
  return (
    <Box sx={{ borderRadius: 2 }}>
      <Grid container spacing={3}>
        {defaultChartConfig.map((config) => {
          const progress = getProgress(processedData, config);

          return (
            <Grid item xs={12} key={config.title}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: styles.modBackgroundBlocks,
                  boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)"
                }}
              >
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                  }}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: config.color
                      }}
                    >
                      {config.title}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    {/* <Typography variant="h6" color={styles.color}>
                      {Math.round(progress.value)} {config.unit}
                    </Typography> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        justifyContent: "flex-end"
                      }}
                    >
                      {progress.isPositive ? (
                        <ArrowUpward
                          sx={{ fontSize: 16, color: "success.main" }}
                        />
                      ) : (
                        <ArrowDownward
                          sx={{ fontSize: 16, color: "error.main" }}
                        />
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          color: progress.isPositive
                            ? "success.main"
                            : "error.main"
                        }}
                      >
                        {Math.abs(progress.change).toFixed(1)}% from yesterday
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <LineChart
                  height={300}
                  series={[
                    {
                      data: processedData.map((item) => item[config.dataKey]),
                      label: config.title,
                      color: config.color,
                      showMark: true,
                      area: true
                    },
                    {
                      data: Array(processedData.length).fill(config.target),
                      label: `Target (${config.target}${config.unit})`,
                      color: theme.palette.error.main,
                      dashPattern: [5, 5]
                    }
                  ]}
                  slotProps={{}}
                  xAxis={[
                    {
                      data: processedData.map((item) => item.date),
                      scaleType: "time",
                      min: minDate,
                      max: maxDate,
                      valueFormatter: (value) => dayjs(value).format("MMM D"),
                      tickNumber: processedData.length,
                      tickLabelStyle: {
                        fill: theme.palette.text.primary,
                        fontSize: 12
                      }
                    }
                  ]}
                  yAxis={[
                    {
                      tickLabelStyle: {
                        fill: styles.color,
                        fontSize: 12
                      }
                    }
                  ]}
                  sx={{
                    ".MuiLineElement-root": {
                      strokeWidth: 3
                    },
                    svg: {
                      fill: "blue"
                    },
                    ".MuiChartsLegend-label": {
                      fill: theme.palette.info.main, // Default text color for legend labels
                      fontSize: "0.875rem"
                    },
                    ".MuiChartsLegend-root tspan": {
                      fontSize: "0.9rem",
                      fill: `${styles.color} !important` // Force override inline styles
                    },
                    ".MuiChartsAxis-label": {
                      fill: theme.palette.text.primary,
                      fontSize: "0.875rem"
                    },
                    ".MuiMarkElement-root": {
                      //   stroke: config.color,
                      scale: "0.6",
                      fill: "white",
                      strokeWidth: 2
                    },
                    ".MuiAreaElement-root": {
                      fillOpacity: 0.1
                    }
                  }}
                />
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MuiNutritionCharts;
