import React, { useState } from "react";
import {
  Typography,
  Button,
  Snackbar,
  Box,
  Alert,
  useMediaQuery,
  styled
} from "@mui/material";
import { ReactComponent as GymIconSVG } from "../../assets/gym.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach.svg";
import { ReactComponent as ActiveCoachIcon } from "../../assets/activecoach.svg";
import { ReactComponent as CompanyIconSVG } from "../../assets/company.svg";
import { ReactComponent as AffiliateIconSVG } from "../../assets/affiliate.svg";
import { Link, useNavigate } from "react-router-dom";

const GymIcon = styled(GymIconSVG)(({ selected }) => ({
  "& path:first-of-type": {
    fill: selected === "Gym" ? "#38B65B" : "#374151"
  }
}));

const CompanyIcon = styled(CompanyIconSVG)(({ selected }) => ({
  "& path:first-of-type": {
    fill: selected === "Company" ? "#38B65B" : "#374151"
  }
}));

const Register = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const matches = useMediaQuery("(max-width:590px)");
  const navigate = useNavigate();
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleProceed = () => {
    if (!selectedRole) {
      setSnackbarMessage("Please select a role.");
      setOpenSnackbar(true);
      return;
    } else {
      // Proceed to the next step
      navigate("/partners/signup", { state: { role: selectedRole } });
    }
  };

  return (
    <Box
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100vh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100vh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        justifyContent="center"
        sx={{
          paddingTop: { xs: 4, md: 8, lg: 10 },
          paddingRight: { xs: 5, md: 13, lg: 20 },
          paddingBottom: { xs: 5, md: 8, lg: 10 },
          paddingLeft: { xs: 5, md: 10 },
          height: { xs: "93%", md: "80%" },
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(255, 255, 255, 0.5)",
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "6px"
          }
        }}
      >
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="30px"
          fontWeight="bold"
          gutterBottom
        >
          Partners Sign Up
        </Typography>
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          color="#6B7280"
          fontSize="16px"
          gutterBottom
        >
          Choose your role to get started
        </Typography>
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box mt={4} display="flex" flexDirection="column" gap={4}>
          <Button
            fullWidth
            onClick={() => handleRoleSelect("Gym")}
            sx={{
              border: "1px solid #D1D5DB",
              borderRadius: "16px",
              backgroundColor:
                selectedRole === "Gym" ? "#D7F0DE" : "transparent"
            }}
          >
            <Box
              width="100%"
              ml={5}
              mr={5}
              mt={2}
              mb={2}
              display="flex"
              gap="16px"
              textTransform="none"
              alignItems="center"
            >
              <GymIcon selected={selectedRole} />
              <Box textAlign="start">
                <Typography fontSize="20px" fontWeight="bold" color="#14142B">
                  Gym
                </Typography>
              </Box>
            </Box>
          </Button>
          <Button
            fullWidth
            onClick={() => handleRoleSelect("Coach")}
            sx={{
              border: "1px solid #D1D5DB",
              borderRadius: "16px",
              backgroundColor:
                selectedRole === "Coach" ? "#D7F0DE" : "transparent"
            }}
          >
            <Box
              width="100%"
              ml={5}
              mr={5}
              mt={2}
              mb={2}
              display="flex"
              gap="16px"
              textTransform="none"
              alignItems="center"
            >
              {selectedRole !== "Coach" ? (
                <CoachIcon height={45} width={45} />
              ) : (
                <ActiveCoachIcon height={45} width={45} />
              )}
              <Box textAlign="start">
                <Typography fontSize="20px" fontWeight="bold" color="#14142B">
                  Coach
                </Typography>
              </Box>
            </Box>
          </Button>
          <Button
            fullWidth
            onClick={() => handleRoleSelect("Company")}
            sx={{
              border: "1px solid #D1D5DB",
              borderRadius: "16px",
              backgroundColor:
                selectedRole === "Company" ? "#D7F0DE" : "transparent"
            }}
          >
            <Box
              width="100%"
              ml={5}
              mr={5}
              mt={2}
              mb={2}
              display="flex"
              gap="16px"
              textTransform="none"
              alignItems="center"
            >
              <CompanyIcon selected={selectedRole} />
              <Box textAlign="start">
                <Typography fontSize="20px" fontWeight="bold" color="#14142B">
                  Company
                </Typography>
              </Box>
            </Box>
          </Button>
          <Button
            fullWidth
            onClick={() => handleRoleSelect("Affiliate")}
            sx={{
              border: "1px solid #D1D5DB",
              borderRadius: "16px",
              backgroundColor:
                selectedRole === "Affiliate" ? "#D7F0DE" : "transparent"
            }}
          >
            <Box
              width="100%"
              ml={5}
              mr={5}
              mt={2}
              mb={2}
              display="flex"
              gap="16px"
              textTransform="none"
              alignItems="center"
            >
              <Box
                height={48}
                width={48}
                borderRadius={"50%"}
                sx={{
                  background:
                    selectedRole === "Affiliate" ? "#38B65B" : "#374151"
                }}
              >
                <AffiliateIconSVG />
              </Box>
              <Box textAlign="start">
                <Typography fontSize="20px" fontWeight="bold" color="#14142B">
                  Affiliate
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
        <Button
          fullWidth
          variant="contained"
          sx={{
            marginTop: "4em",
            textTransform: "none",
            backgroundColor: "#38B65B",
            color: "#fff",
            fontWeight: "bold",
            ":disabled": {
              backgroundColor: "#D7F0DE",
              color: "#9CDBAD",
              pointerEvents: "none"
            }
          }}
          onClick={handleProceed}
          disabled={!selectedRole}
        >
          Proceed
        </Button>
        <Box
          mt={4}
          display="flex"
          gap="20px"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Typography userSelect="none">Already Member?</Typography>
          <Link
            to="/login"
            variant="outlined"
            style={{
              color: "#38B65B",
              textDecoration: "none"
            }}
          >
            Sign In
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
