import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as StreaksIcon } from "../../assets/streak.svg";
import { ReactComponent as ChartIcon } from "../../assets/chart.svg";
import { ReactComponent as DollarIcon } from "../../assets/dollar.svg";
import { useDispatch } from "react-redux";
import { setLimit, setPage } from "../../features/users/userSlice";
import { TableSortLabel, Typography } from "@mui/material";
import getInitials from "../../utils/getInitials";
import dayjs from "dayjs";
import useSignedUrl from "../../utils/useSignedUrl";
import StatsListModal from "../Modals/StatsModal";
import BadgeDisplay from "../Extra/BadgeDisplay";

// Custom CSS styles
const styles = {
  tableContainer: {
    borderRadius: "10px 10px 0 0",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#374151",
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1"
    }
  },
  headerCell: {
    minWidth: 120,
    backgroundColor: "#374151",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center" // Center the header text
  },
  tableRow: {
    fontSize: "12px"
  },
  activeStatus: {
    fontSize: "12px",
    color: "#2e7d32", // Darker green text for active
    backgroundColor: "#e8f5e9", // Light green background
    padding: "5px",
    width: "70%",
    borderRadius: "5px"
  },
  inactiveStatus: {
    fontSize: "12px",
    color: "#d32f2f", // Darker red text for inactive
    backgroundColor: "#ffebee", // Light red background
    padding: "5px",
    width: "70%",
    borderRadius: "5px"
  }
};

const columns = (currentTab, isAdmin) => [
  { id: "fullName", label: "Name", minWidth: 120 },
  { id: "email", label: "Email", minWidth: 120 },
  isAdmin ? { id: "userType", label: "Role", minWidth: 60 } : null,
  isAdmin
    ? {
        id: "referenceId",
        label:
          currentTab !== "All"
            ? currentTab
            : "Coach / Gym / Company / Affiliate",
        minWidth: 120,
        align: "center"
      }
    : null,
  { id: "createdAt", label: "Joined", minWidth: 60, align: "center" },
  { id: "lastLogin", label: "Last login", minWidth: 70, align: "center" },
  { id: "streak", label: "Streak", minWidth: 50, align: "center" },
  { id: "badges", label: "Badges", minWidth: 110, align: "center" },
  { id: "isActive", label: "Status", align: "left" },
  { id: "actions", minWidth: 130, align: "center" }
];
const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      borderRadius="50%"
      height={40}
      width={40}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const StickyHeadTable = ({
  isAdmin = true,
  totalUsers,
  users,
  loading,
  onEdit,
  setConfirmDeleteModal,
  error,
  currentTab,
  order,
  setOrder,
  orderBy,
  setOrderBy
}) => {
  const [openStats, setOpenStats] = React.useState({}); // Modal state
  const [page, setNewPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setNewPage(newPage);
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setNewPage(0);
    dispatch(setPage(0));
    dispatch(setLimit(+event.target.value));
  };

  const getRoleChipStyle = (role) => {
    switch (role) {
      case "Gym":
        return {
          style: {
            backgroundColor: "#D7F0DE",
            fontSize: "12px",
            color: "#38B65B"
          },
          label: "Gym"
        };
      case "user":
      case "Affiliate":
        return {
          style: {
            backgroundColor: "#FCECD3",
            fontSize: "12px",
            color: "#F1B44C"
          },
          label: "User"
        };
      case "admin":
        return {
          style: {
            backgroundColor: "#D7F0DE",
            fontSize: "12px",
            color: "#38B65B"
          },
          label: "Admin"
        };
      case "Coach":
        return {
          style: {
            backgroundColor: "#FDD9CD",
            fontSize: "12px",
            color: "#F6541C"
          },
          label: "Coach"
        };
      case "Company":
        return {
          style: {
            backgroundColor: "#D4E9FC",
            fontSize: "12px",
            color: "#368AD0"
          },
          label: "Company"
        };
      default:
        return { style: {} };
    }
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
    // Update the sorting logic here as needed
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns(currentTab, isAdmin)
                ?.filter(Boolean)
                ?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{
                      ...styles.headerCell,
                      ...(column.label === "Status" && { textAlign: "left" })
                    }}
                  >
                    {!["streak", "badges"].includes(column.id) ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      <>{column.label}</>
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                <TableCell style={styles.tableRow} align="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                    gap={2}
                  >
                    {row?.profilePic ? (
                      <ImageItem img={row?.profilePic} />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }}>
                        {row.fullName
                          ? getInitials(row.fullName)
                          : getInitials(row.displayName)}
                      </Avatar>
                    )}
                    <Typography fontSize="small">
                      {row.fullName ? row.fullName : row.displayName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={styles.tableRow} align="center">
                  {row.email}
                </TableCell>
                {isAdmin ? (
                  <>
                    <TableCell style={styles.tableRow} align="center">
                      <Chip
                        label={
                          getRoleChipStyle(
                            row.isAdmin === 1
                              ? currentTab === "All"
                                ? row.userType
                                : "admin"
                              : "user"
                          ).label
                        }
                        sx={
                          getRoleChipStyle(
                            row.isAdmin === 1
                              ? currentTab === "All"
                                ? row.userType
                                : "admin"
                              : "user"
                          ).style
                        }
                      />
                    </TableCell>
                    <TableCell style={styles.tableRow} align="center">
                      {row.referenceId ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          gap={2}
                        >
                          {row?.userTypeDetails?.profilePic ? (
                            <ImageItem img={row?.userTypeDetails?.profilePic} />
                          ) : (
                            <Avatar sx={{ width: 40, height: 40 }}>
                              {getInitials(
                                row.userTypeDetails?.[
                                  `${row.userType?.toLowerCase()}Name`
                                ]
                              )}
                            </Avatar>
                          )}
                          <Typography fontSize="small">
                            {
                              row.userTypeDetails?.[
                                `${row.userType?.toLowerCase()}Name`
                              ]
                            }
                          </Typography>
                        </Box>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  </>
                ) : null}

                <TableCell style={styles.tableRow} align="center">
                  {dayjs(row.createdAt).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell style={styles.tableRow} align="center">
                  {row.lastLogin
                    ? dayjs(row.lastLogin).format("MM/DD/YYYY")
                    : "N/A"}
                </TableCell>
                <TableCell style={styles.tableRow} align="center">
                  <Box
                    display={"flex"}
                    gap="7px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StreaksIcon height={20} width={20} />
                    <Typography variant="bold">{row.streaks}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  <BadgeDisplay open={row} />
                </TableCell>
                <TableCell align="center" style={styles.tableRow}>
                  <Typography
                    margin={0}
                    style={
                      row.isActive ? styles.activeStatus : styles.inactiveStatus
                    }
                  >
                    {row.isActive ? "Active" : "Inactive"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" gap="20px">
                    {(isAdmin ||
                      !["Company", "Affiliate"].includes(row.userType)) && (
                      <IconButton
                        onClick={() => {
                          if (row.isActive) setOpenStats(row);
                        }}
                        sx={{
                          height: "32px",
                          width: "32px",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "4px"
                        }}
                        size="small"
                        aria-label="edit"
                      >
                        <ChartIcon fontSize="small" />
                      </IconButton>
                    )}
                    {isAdmin ? (
                      <IconButton
                        sx={{
                          height: "32px",
                          width: "32px",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "4px"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const customerDashboardUrl =
                            process.env.REACT_APP_STRIPE_PUBLIC_KEY?.includes(
                              "live"
                            )
                              ? process.env
                                  .REACT_APP_STRIPE_CUSTOMER_DASHBOARD_URL
                              : process.env
                                  .REACT_APP_TEST_STRIPE_CUSTOMER_DASHBOARD_URL;
                          const customerId = row?.stripeCustomerId;
                          window.open(
                            `${customerDashboardUrl + customerId}`,
                            "_blank"
                          );
                        }}
                        size="small"
                        aria-label="view"
                      >
                        <DollarIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      onClick={() => {
                        onEdit(row);
                      }}
                      size="small"
                      aria-label="info"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      onClick={() => {
                        setConfirmDeleteModal(row);
                      }}
                      size="small"
                      aria-label="delete"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <StatsListModal open={openStats} setOpen={setOpenStats} />
    </Paper>
  );
};

export default StickyHeadTable;
